.welcome-message-container {
    text-align: center;
    padding: 20px;
    margin-top:20px;
}

.code-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.code-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

.submit-button {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #5C623D;
    color: white;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #4d5233;
}

.error-message {
    margin-top: 10px;
    color: red;
}
