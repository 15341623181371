.alert-cols {
    display: flex;
    flex-direction: column;
    padding: 0em 3em 0.5em 3em;
    width: 90%;
    text-align: center;
    font-size: 20px;
}

.alert-row {
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding: 1em;
}

.info-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:800px;
  }


.alert-title {
    font-weight: bold;
    color: #5C623D;
    padding-left: 2em;
    /* font-size: 17px; */
}

.alert-value {
    color: #5C623D;
    font-weight: normal;
    flex-basis: 63%;
    margin-left: 20px;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: .5em 2em 3em 2em;
    width: 90%;
    gap: 20px;
    padding: 0em 3em 0.5em 3em;
}

.button-container button {
    /* width: 26%; */
}

.alert-info-button {
    padding: 0.65em 1em;
    background-color: #C4D5C5;
    font-size: 17px;
    
    margin: 6px;
    border-radius: 10px;
    font-weight: bold;
}

.modal {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
    height:310px;
    z-index: 100;
}

.close-cancel {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
}

.close-cancel button {
    margin: 0.2em 0.2em;
}

.modal h2 {
    color: #5C623D;
    font-size: 20px;
}

.modal-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0.2em 0;
    font-size: 1em;
    cursor: pointer;
    /* background-color: white; */
    color: black;
    /* border: none; */
    border-radius: 4px;
    border: solid 1.5px #dcdcdc;
}

.modal-button.selected {
    border: solid 2px #5C623D;
}

.modal-button:hover {
    border: solid 2px #5C623D;
}

.alert-cancel {
    background-color: transparent;
    border: solid 1.5px #cbcbcb;
}

.alert-confirm {
    background-color: #5C623D;
    color: white;
    border: none;
}

.alert-cancel:hover {
    background-color:#e8e8e8;
    border: solid 1.5px #e8e8e8;
}

.alert-confirm:hover {
    background-color: #5C623D;
    color: white;
    border: none;
}

.alert-button-container {
    padding: 1em;
}

.profile-fields {
    width: 100%;
    padding: 1em;
}

.item, .suite {
    color: #5C623D;
    font-size: 16px;
    padding: 1.75em 1em;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align:left;
    display:flex;
    margin: 0.5em;
    flex-direction: column;
}

.item-list {
    padding: 0.5em 1em;
}

.item p, .suite p {
    margin:0;
}

.item h3, .suite h3 {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
}

.suite-list {
    display: flex;
}




/* Media queries for tablets */
@media only screen and (max-width: 950px) {
    .alert-cols {
        padding: 0em 1em 0.5em 1em;
    }

    .button-container button {
        font-size: 20px;
    }

}

/* Media queries for iPhones */
@media only screen and (max-width: 650px) {

    .alert-row {
        padding: 1em 0 1em 0;
        flex-direction: column;
        /* align-items: center; */
        gap: 5px;
    }

    .alert-value {
        margin:0;
        /* text-align: center; */
    }

    .alert-title {
        padding:0;
    }

    .info-page {
        height: 76vh;
    }

    .content-container {
        height:58vh;
        overflow-y: scroll;
        overflow-x: hidden;
      }

    .button-container {
        flex-direction: column;
        gap: 5px;
        /* position:fixed;
        bottom:12px; */
        padding:0em 1em 1.5em 1em;
        margin:0
    }
    .button-container button {
        width: 100%;
    }

}