.NewHome{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: fit-content;
} 

.alerts-section, .benefit-tracker-section, .messages-notifications-section {
  text-align: center;
  background-color: #ffffff; /* Change as per your design */
  /* box-shadow: 0 2px 8px 0 rgb(0 0 0 / 0%), 0 3px 15px 0px rgba(0, 0, 0, 0.19); */
  border-radius: 20px;
  align-self: center;
  margin-bottom: 60px;
  padding: 0;
  width: 90%
}

.alerts-header, .benefit-tracker-header, .messages-notifications-header {
  /* background-color: #C4D5C5;  */
  background-color: #CCDAC5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 30px;
  width: 100%;
  height: 85px;
  font-size: 18px;
  border-radius: 20px 20px 0px 0px; /* Adjust as needed */
  margin-bottom: 20px;
  color: #5C623D;
}


.alerts-header h2, .benefit-tracker-header h2, .messages-notifications-header h2{
  font-weight: bold;
  margin: 0;
}

.alerts-container, .benefit-tracker-container, .messages-notifications-container {
  display: flex;
  justify-content: space-evenly;
  background-color: #ffffff;
  text-align: center;
  padding: 0 0 20px 0;
  border-radius: 20px;
  align-items: flex-start;
}

.alert-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  cursor: pointer;
}

.alert-icon {
  justify-self: center;
  /* width: 100px; */
  height: 120px; /* Adjust as needed */
  margin-bottom: 10px;
}

.alert-info {
  width: 150px;
  align-self: center;
  font-size: 25px; /* Adjust as needed */
  font-weight: bold;
  background-color: #ffffff;
  color: #5C623D;
  font-weight: bold;
  font-size: 21px;
}

.alert-status {
  display: flex;
  justify-content: center;
  gap: 10px; /* Space between 'New' and 'In Process' */
  flex-direction: column;
  /* font-size: 20px; */
  align-items: flex-end;
}

.new-alert,
.in-process-alert {
  padding: 5px 14px;
  font-size: 22px; /* Adjust as needed */
  border-radius: 50%; /* Adjust as needed */
  color: #fff;
}

.new-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* color: #6d6d6d */
}

.new-1 p{
  margin: 0 10px 0 0;
}

.new-alert {
  background-color: #f44336; /* Change as per your design */
}

.in-process-alert {
  background-color: #5cff3b; /* Change as per your design */
}

.benefit-tracker-header h2{
  font-weight: bold;
}

.benefit-tracker-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.benefit-tracker-card img {
  height: 110px; 
}

.benefit-tracker-card h3 { 
  margin-top: 10px;
  font-size: 22px; 
  font-weight: bold;
  color: #5C623D;
}

.messages-notifications-header h2{
  font-weight: bold;
}

.notification-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:208px;
}

.notif-icon, .alert-icon-holder {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative; /* For positioning the notification badge */
  width: 100%;
}

.notification-card img {
  height: 150px;
}

.notification-card p {
  margin-top: 10px;
  font-weight: bold;
  font-size: 22px;
  color: #5C623D;
}

.notification-badge, .alert-badge {
  background-color: #f44336; /* Red color for the badge */
  color: white;
  padding: 4px 14px;
  border-radius: 50%; 
  position: absolute;
  top: 0px;
  right: 12px;
  font-size: 22px; 
  min-width: 20px; 
  text-align: center;
}

.alert-badge {
  top: 5px;
  right: 6px;
}

.benefit-circle {
  display: none;
}

::-webkit-scrollbar-track {
  border-left: 1px solid var(--outline-dimmest);
}

.home-hr {
  width:75%;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  height: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #5C623D, -20px 0 #0002;background: #5C623D }
    33% {box-shadow: 20px 0 #5C623D, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #5C623D; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #5C623D; background: #5C623D }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

/* Media queries for iPhones */
@media only screen and (max-width: 650px) {
  .NewHome {
    flex-direction: column;
    justify-content: center;
    max-height: fit-content;
  }

  .home-hr {
    display: none;
  }

  .alerts-section, .benefit-tracker-section, .messages-notifications-section {
    max-width: 100%;
    margin: 5px;
  }

  .alerts-header,
  .benefit-tracker-header,
  .messages-notifications-header {
    width: 100%;
    height: 46px;
    padding: 20px;
    margin-bottom: 10px;
  }

  .alerts-header h2, .benefit-tracker-header h2, .messages-notifications-header h2{
    font-size: 20px;
  }

  .alerts-container {
    flex-direction: column;
    padding-bottom: 10px;
    gap: 7px;
  }  

  .alert-card {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 32px; /*used to be 20 */
    padding: 0;
  }

  .alert-info  {
    text-align: left;
  }

  .benefit-tracker-container {
    overflow-x: scroll;
    padding: 0 25px 10px 25px;
    gap:10px; 
    /* gap was 50 */
  }

  /* .messages-notifications-container {
    padding-bottom: 10px;
  } */

  .benefit-tracker-card h3 {
    font-size: 15px;
    margin: 0;
  }

  .alert-icon {
    height: 60px;
    margin: 0;
  }

  .benefit-tracker-card img {
    max-height: 75px;
    object-fit: contain;
  }

  .alert-icon-holder {
    width: 84px;
  }

  .new-alert,
  .in-process-alert {
    font-size: 18px;
  }

  .notification-card img {
    height: 95px;
  }

  .notification-card p {
    font-size: 18px;
  }

  .notification-badge,
  .alert-badge {
    font-size: 18px;
    padding: 2px 10px;
  }

  .notification-badge {
    top: 4px;
    right: 40px;
  }

  .alert-badge {
    right: -2px;
  }

  .notif-icon img {
    height: 70px;
  }

  .notification-card p {
    font-size: 16px;
  }

 .notification-card {
  width: 152px;
}

  .messages-notifications-container p {
    margin: 0;
  }

  /* .messages-notifications-section {
    margin-bottom: 18px;
  } */
  .notification-badge {
    right: 27px;
  }

  .notif-icon img {
    height: 60px;
  }

  /********************* 
  **********************/

  /********************* 
  **********************/
  /* benefit tracker - new */

  .benefit-tracker-container {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .benefit-tracker-card img {
    display:none;
  }

  .benefit-tracker-card {
    flex-direction: row;
    font-size: 17px;
    justify-content: space-evenly;
    width: 100%;
    gap: 20px;
    padding: 7px;
    text-align: left;
  }

  .benefit-tracker-card h3 {
    font-size: 21px;
    width: 40%;
  }

  .status-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 24px;
    /* padding: 9px 13px; */
    padding: 4px 13px;
    gap: 14px;
    font-size: 18px;
    width: 45%;
  }

  .box-benefit {
    width: 60px;
    font-size: 22px;
  }


  .small-screens {
    height: 70px;
  }

  .small-screens h2 {
    width: 55%;
  }
}

@media only screen and (min-width: 651px) {
  .status-box {
    width: 90%;
    display: flex;
    justify-content: center;
    gap:14px;
  }

  .benefit-tracker-card {
    gap:10px;
  }
  
}