.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: rgb(204, 218, 197); */
   /* margin-bottom: 5px; */
  border-radius: 0 0 10% 10%; 
  top: 0;
  height: 50px;
  padding: 2em;
  /* position:fixed;
  width:100%; */
  }

  .menu-icon {
    color: #5C623D;
    cursor: pointer;
  }
  
  .logo {
    width: 78px;
    height: auto; /* Height of the logo */
  }
  
  .all-buildings {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px; /* Font size for the "All Buildings" text */
    color: #5C623D;
    gap: 10px;
    font-weight: bold;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    margin-left: 30px;
    /* Additional styling if needed */
  }
 
  .user-image {
    height: 33px;
  }
  
  .user-name {
    font-size: 35px;
    margin-right: 80px;
    margin-top: 100px;
    font-weight: bold;
 /* Font size for the username */
    /* Additional styling if needed */
  }
  


@media screen and (max-width: 650px) {
  .buildings {
    display: none;
  }

  .header-container {
    padding: 2em;
    height: 50px;
  }

  .logo {
    width: 78px;
  }

} 
