.assign-alert, .alert-status-update {
    padding: 0em 6em 2em 6em;
}

.dropdown-container {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
   font-size: 17px;
   color: #5C623D;
   
}

.assign-alert-dropdown, .alert-status-dropdown {
    border-color: #5C623D;
    border-radius: 6px;
    border: none;
    background-color: #ededed;
}

.assign-alert-dropdown.content{
    background-color: white;
}

.service-contractor-content, .building-team-content,
.tenant-content, .no-select, .service-needed-content,
.service-scheduled-content {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2em 0 0 0;
    gap: 10px;
}

.service-needed-content {
    height: 450px;
}

.service-scheduled-content {
    height: 160px;
    color:#5C623D;
}

.no-border-input {
    padding: 0.5em;
    font-size: 1em;
    border: none; /* Remove the border */
    outline: none; /* Remove the outline when focused */
}

.feedback-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2em 0 0 0;
    gap: 10px;
}

.assign-alert textarea, .service-needed-content textarea, .feedback-content textarea {
    width: 100%;
    height: 100px;
    border-radius: 6px;
    border: solid 1.5px #bdbdbd;
    box-shadow: none;
    min-height: 50px;
    margin-bottom: 6px;
}

.feedback-content input[type="email"] {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: solid 1.5px #bdbdbd;
    box-shadow: none;
    margin-bottom: 6px;
}

.text-header {
    font-size: 17px;
    color: #5C623D;
}

.input-header {
    margin-bottom:1em;
    font-size: 17px;
    color: #5C623D;
}

.feedback-type, .contact-preference {
    display: flex;
    width: 80%;
    gap: 10px;
    flex-wrap: wrap;
    
}

.feedback-type label, .contact-preference label {
    display:flex;
    gap:10px;
    align-items: center;
    margin-left: 12px;
}

.photo-label {
    display: flex;
    gap: 10px;
    font-size: 17px;
    color: #5C623D;
    /* margin: 0.5em 0; */
    cursor: pointer;
    align-items: center;
}

.alert-button-container {
    width: 100%;
    padding: 0.5em;
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
}

.checkbox-container label {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em; 
}

.checkbox-container input[type="checkbox"] {
    margin-right: 0.5em; 
    color: #5C623D;
}

.content-wrapper {
    overflow-y: scroll;
    height: 77vh;
}

@media only screen and (max-width: 750px) {
    .dropdown-container {
        font-size: 20px;
    }
}

@media only screen and (max-width: 650px) {
    .alert-status-update {
        padding: 0;
    }
    .dropdown-container {
        justify-content: center;
        padding:10px;
    }

    .service-needed-content, .service-scheduled-content, .feedback-content {
        padding: 0 1.25em;
        font-size: 17px;
        gap: 22px;
    }

    .checkbox-container {
        gap: 10px;
    }

    .photo-label {
        font-size: 20px;
    }

    .text-header {
        font-size: 20px;
    }

    .input-header {
        font-size: 20px;
        text-align: left;
    }
    .alert-info-button { 
        font-size: 20px;
    }

    .checkbox-container label {
        text-align: left;
        gap: 10px;
        font-size: 20px;
    }

    .assign-alert {
        padding: 0;
    }
    .selected-option-content {
        padding: 10px;
    }
    
}