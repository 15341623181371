.table-container {
    display: flex;
    justify-content: center;
    margin: 0em 1em 3em 1em;
}

.data-header {
    font-size: 17px;
    font-weight: bold;
    color: #5C623D;
    margin-bottom: 0;
    margin-top: 1em;
}

/* the table */
.sensor-data-table {
    border-collapse: collapse; /* Remove the gaps between cells */
    margin: 20px 0; 
    font-size: 1em;
    width: 85%;
    text-align: center;
    max-width: 85%;
    table-layout: fixed;
    
}

/* table headers */
.sensor-data-table thead th {
    background-color: #C4D5C5; 
    text-align: center; 
    font-weight: bold; 
    border: 1px solid #dddddd; 
}

/* table cells */
.sensor-data-table th,
.sensor-data-table td {
    padding: 12px 15px; 
    border: 1px solid #dddddd; 
    word-wrap: break-word;
}

/* table rows */
.sensor-data-table tbody tr {
    border-bottom: 1px solid #dddddd; /* Add border to table rows */
}


@media only screen and (max-width: 825px) {
    .table-container {
        width: 90%;
        justify-content: flex-start;
        overflow-x: scroll;
    }

    .data-header {
        font-size: 22px;
    }

    .height-wrapper {
        /* height:87vh; */
    }

    .sensor-data-table {
        font-size: 1.25em;
    }

    .sensor-data-table thead th {
        width: 150px;
    }
}