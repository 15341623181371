
  .signup__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff; /* Assuming a white background */
    font-family: 'Arial', sans-serif; /* This should be your desired font */
  }

  .signup h5 {
    font-size: 3em;
    width:100%;
    font-weight: bold;
    color: #5C623D;
    align-items: flex-start;
    margin-bottom: 0.5em;
  }

  .signup__box {
    width: 90%; 
    margin-top: 2em;
    padding: 20px;
    box-sizing: border-box;
  }

  .signup p {
    font-size: 1.25em; /* Smaller text for the subheading */
  color: #6d7955; /* A grey for subtext */
  margin-bottom: 1.5em;
  }
  

.signup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup__box > button:hover{
  background-color: #C4D5C5;
    opacity: 0.8;
}

.signup-content > input{
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(88, 88, 88);
  padding: 15px;
  margin-bottom: 0.5em;
  font-size: 1.25em;
}

.signup-content > select {
  width:80%;
}

.signup__box2{
    padding:20px 35px;
    margin-top: 10px;
    border:1px solid lightgray;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.signup__link{
    text-decoration: none !important;
}

.app_name{
    color: #07502d;
    font-size: 30px;
    font-weight: bold;
    /* margin-bottom: 60px; */
}

.signup__box2 > h6{
  color: #07502d !important;
}
.cancel-button {
  align-self: center;
  font-size: 1em;
  color: #CCCCCC;/* Theme color for the cancel button */
  text-decoration: underline;
  cursor: pointer;
}