.dashboard {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardCard {
  display: grid;
  /* margin: 1rem; */
  border-radius: 2rem;
  background-color: white;
  /* opacity: .4; */
  overflow: hidden;
  grid-template-columns: 11rem auto;
  /* gap: 15px; */
}

.appbar {
  display: flex;
  position: fixed;
  padding: 0.75rem;
  height: 70px;
  align-items: center;
  border-bottom-width: 2px;
  width: 100%;
  z-index: 10;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .sidebar-content {
    margin-left: 0px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
}

@media (min-width: 768px) {
  .sidebar-content {
    /* Uncomment below later */
    /* margin-left: 300px; */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin-top: 0px;
  }
}