/* @media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  } */
  /* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; /* Assuming a white background */
  font-family: 'Arial', sans-serif; /* This should be your desired font */
}

.login-box {
  width: 80%; /* Or a fixed width, depending on your design */ /* Ensures the box doesn't get too wide on larger screens */
  margin-top: 22%;
  padding: 10px;
  box-sizing: border-box;
}

.login-box h1 {
  font-size: 3.2em; /* Large text for the main heading */
  margin-bottom: 0.5em;
  font-weight: bold;
  color: #5C623D;
}

.login-box p {
  font-size: 1.65em; /* Smaller text for the subheading */
  color: #6d7955; /* A grey for subtext */
  margin-bottom: 3em;
}

.login-box input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(88, 88, 88);
  padding: 15px;
  margin-bottom: 0.5em; /* Space between input fields */
  font-size: 2.5em;
}
input::placeholder{
  color: #6d7955;
}
[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease; 
  text-indent: -100%;
  opacity: 1;
}

.login-button {
  width: 60%;
  padding: 10px;
  margin-bottom: 1em;
  font-weight: bold;
  border: none;
  border-radius: 15px;
  background-color: #C4D5C5;/* Adjust to your theme color */
  color: rgb(0, 0, 0);
  font-size: 1.25em;
  cursor: pointer;
}

.login-box .forgot-password-link {
  text-align: end;
  margin-top: -2%;
  margin-right: -35%;
  font-weight: bold;
  color: #5C623D; /* Theme color for the link */
  text-decoration: none;
  margin-bottom: 25%; /* Space before the login button */
  font-size: 1.25em;
}

.login-box .cancel-button {
  align-self: center;
  font-size: 1.5em;
  margin:0;
  color: #CCCCCC;/* Theme color for the cancel button */
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}