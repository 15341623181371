.AlertPage, .BenefitPage, .FeedbackPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: fit-content;
} 

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.alert-body {
    margin: 0 10px 40px 10px;
}

.alert-tabs {
    display: flex;
    flex-direction: row;
    align-items:start;
    font-size: 20px;
}

.tab-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 1em 2em;
    /* margin: 0 10px; */
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.tab-container img {
    width: 50px;
}

.tab-container ul {
    padding: 0;
    margin: 0;
    color: #5C623D;
}

.tab-container.active {
    background-color: #d3d3d36b; /* Highlight active tab */
}

.alert-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5em;
    padding: 0.75em 2em;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.alert-heading h3 {
    font-size: 20px;
    margin: 0;
    color: #5C623D;
}

.heading-titles {
    display: flex;
    flex-direction: row;
}

.alert-display .new.active,
.alert-display .in-progress {
    display: none;
    overflow-y: auto;
    height: 55vh;
}

.alert-display .new,
.alert-display .in-progress {
    display: block;
    overflow-y: auto;
    height: 48vh;
} 

.alert-display {
    display: block;
    background-color: #d3d3d36b; 
    flex-grow: 1;
    padding: 20px;
    font-size: 16px;
}

.no-alerts {
    font-size: 22px;
    padding: 25px;
    color: #5C623D;
}

/* Media queries for iPhones */
@media only screen and (max-width: 650px) {
    .alert-display {
        padding: 15px 5px 5px 5px;
    }

    .alert-heading {
        padding: 1.25em 2em;
    }

    .heading-titles h3 {
        font-weight: bold;
    }


}