.card-box {
    display: flex;
    border-radius: 10px;
    border: 1px solid transparent;
    background-color: white;
    /* cursor: pointer; */
    align-items: center;
    justify-content: space-between;
    margin: 0.5em;
    padding: 2.5em 1em;
}

.description-text {
    max-width: 400px; 
    word-wrap: break-word; /* Ensures long words break and wrap to the next line */
    overflow-wrap: break-word; 
    text-align:left;
}